<template>
  <div>
    <el-button
      type="primary"
      @click="exportPDF('exportPdf')"
      size="small"
      style="margin:20px;"
      >Export</el-button
    >
    <di id="exportPdf">
      <div v-for="(note, index) in dataList" :key="index" class="note-item">
        <div class="note_title">{{ note.decryptTitle }}</div>
        <div class="note_content">{{ note.decryptContent }}</div>
      </div>
    </di>
  </div>
</template>
  <script>
import { getMyNotes } from "../../api/reff";
import { getUserId } from "../../utils/store";
import { decryptValue } from "../../utils/common";
export default {
  name: "App",
  data() {
    return {
      dataList: [],
    };
  },
  mounted() {
    this.fetchData();
  },

  methods: {
    fetchData() {
      this.dataList = [];
      let that = this;
      getMyNotes(getUserId()).then((res) => {
        let result = res.data.data;
        for (let i = 0; i < result.length; i++) {
          let content = result[i];
          let des_content_new = content.des_content_new;
          let decryptContent = this.doDecryptValue(des_content_new);
          let des_title_new = content.des_title_new;
          let decryptTitle = this.doDecryptValue(des_title_new);
          content["decryptContent"] = decryptContent;
          content["decryptTitle"] = decryptTitle;
          this.dataList.push(content);
        }
        this.$nextTick(()=>{
          this.exportPDF('exportPdf')
        })
      });
    },
    doDecryptValue(encrypted) {
      return decryptValue(encrypted);
    },
    exportPDF(id) {
      var style = document.createElement("style");
      style.innerHTML = "@page{size:A4 portrait;margin: 0mm;mso-header:none;mso-footer:none}";
      document.getElementsByTagName("body")[0].style.zoom = 1;
      window.document.head.appendChild(style);
      const printData = document.getElementById(id).innerHTML;
      this.PageSetup_Null(); //把页眉页脚设置为空
      window.document.body.innerHTML = printData; // 把 html 里的数据 复制给 body 的 html 数据 ，相当于重置了 整个页面的 内容
      window.print(); // 开始打印
    },
    PageSetup_Null() {
      var HKEY_Root, HKEY_Path, HKEY_Key;

      HKEY_Root = "HKEY_CURRENT_USER";

      HKEY_Path = "\\Software\\Microsoft\\Internet Explorer\\PageSetup\\";

      try {
        var Wsh = new ActiveXObject("WScript.Shell");

        HKEY_Key = "header";

        Wsh.RegWrite(HKEY_Root + HKEY_Path + HKEY_Key, "");

        HKEY_Key = "footer";

        Wsh.RegWrite(HKEY_Root + HKEY_Path + HKEY_Key, "");
      } catch (e) {}
    },
  },
};
</script>
  
  <style scoped lang="less">
.note-item {
  padding: 0px 30px;
  // box-sizing: border-box;
  // width: 210mm;
  // height: 297mm;
  // display: flex;
  // flex-direction: column;
  // z-index: 0;
  page-break-before: always;
}
.model {
  width: 210mm;
  height: 290mm;
}

.note_title {
  margin-top: 30px;
  font-size: 22px;
  line-height: 32px;
  font-family: "Lato";
  font-weight: normal;
  color: #333333;
  padding-bottom: 10px;
  box-sizing: border-box;
  border-bottom-width: 1px;
  border-bottom-color: #999999;
  border-bottom-style: solid;
}
.note_content {
  margin-top: 20px;
  box-sizing: border-box;
  color: gray;
  font-size: 18x;
  line-height: 28px;
  font-family: "Lato";
  box-sizing: border-box;
  font-weight: normal;
  white-space: pre-line;
}


</style>
  